import React, { FC, ButtonHTMLAttributes } from "react";
import heart from "../../../assets/SmallHeart.svg";
import "./Button.scss";

type ButtonVariant = "primary" | "secondary";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant: ButtonVariant;
}

export const Button: FC<ButtonProps> = ({
    variant,
    onClick,
    children,
    ...props
}) => {
    return (
        <button
            type="button"
            className={`button ${variant}`}
            onClick={onClick}
            {...props}
        >
            {children}
            <img src={heart} alt="" />
        </button>
    );
};
