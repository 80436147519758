// ApplicationInput.tsx
import React from "react";
import "./ApplicationInput.scss";
import { TextInput } from "./TextInput/TextInput";
import { TextArea } from "./TextAreaInput/TextAreaInput";
import { Select } from "./SelectInput/SelectInput";
import { RadioInput } from "./RadioInput/RadioInput";

interface IApplicationInputProps {
    type: string;
    name: string;
    id: string;
    label: string;
    options?: string[];
    onChange?: React.ChangeEventHandler<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >;
    value?: string | number;
    error?: string | false | undefined;
}

export function ApplicationInput(props: IApplicationInputProps) {
    return (
        <div className="ApplicationInput">
            {(() => {
                switch (props.type) {
                    case "text":
                        return <TextInput {...props} />;
                    case "select":
                        return <Select {...props} />;
                    case "textarea":
                        return <TextArea {...props} />;
                    case "radio":
                        return <RadioInput {...props} />;
                    default:
                        return <TextInput {...props} />;
                }
            })()}
        </div>
    );
}
