// SelectInput.tsx
import React from "react";
import "./SelectInput.scss";

interface ISelectProps {
    name: string;
    id: string;
    label: string;
    options?: string[];
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
    value?: string | number;
    error?: string | false | undefined;
}

export const Select: React.FC<ISelectProps> = ({
    name,
    id,
    label,
    options,
    onChange,
    value,
    error,
}) => (
    <div className="SelectInput">
        <label htmlFor={name}>{label}</label>
        <select name={name} id={id} onChange={onChange} value={value}>
            <option value="">Please choose an option</option>
            {options &&
                options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
        </select>
        {error && <div className="error-message">{error}</div>}
    </div>
);
