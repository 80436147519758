// TextInput.tsx
import React from "react";
import "./TextInput.scss";

interface ITextInputProps {
    name: string;
    id: string;
    label: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    value?: string | number;
    error?: string | false | undefined;
}

export const TextInput: React.FC<ITextInputProps> = ({
    name,
    id,
    label,
    onChange,
    value,
    error,
}) => (
    <div className="TextInput">
        <label htmlFor={name}>{label}</label>
        <input
            name={name}
            id={id}
            type="text"
            onChange={onChange}
            value={value}
        />
        {error && <div className="error-message">{error}</div>}
    </div>
);
