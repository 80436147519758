import * as React from "react";
import { useState } from "react";

import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { ApplicationInput } from "./ApplicationInput/ApplicationInput";
import "./ApplicationForm.scss";
import { Button } from "../Button/Button";
import ApplicationImage from "../../../assets/Application.png";
import { Modal } from "../Modal/Modal";
import { AddImage } from "./AddImage/AddImage";
import {
    useShareSite,
    schema,
    handleFormSubmit,
    FormValues,
} from "../../utils/ApplicationFormUtils";

export default function ApplicationForm() {
    const { shareSite, modal, setModal } = useShareSite();
    const [sending, setSending] = useState(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [photoTaken, setPhotoTaken] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik<FormValues>({
        initialValues: {
            name: "",
            occupation: "",
            lookingFor: "",
            age: "",
            rizz: "",
            email: "",
        },
        validationSchema: schema,
        validateOnChange: true,
        onSubmit: (values) => {
            handleFormSubmit(
                values,
                setSending,
                setImageSrc,
                setPhotoTaken,
                imageSrc,
                setModal,
                formik,
                navigate
            );
        },
    });

    return (
        <div className="ApplicationForm" id="ApplicationForm">
            {sending && (
                <Modal
                    type="sending"
                    message="Sending your application..."
                    onClose={() => null}
                />
            )}
            {modal.show && (
                <Modal
                    type={modal.type}
                    message={modal.message}
                    onClose={() =>
                        setModal({ show: false, type: "", message: "" })
                    }
                />
            )}
            <img className="formImage" src={ApplicationImage} alt="" />
            <p>
                My name is Jacob, I'm a fourth year computer enginnering major
                at UCSB born and raised in Canada. I love playing guitar,
                listening to The Beatles, and hiking. I'm currently in Whippany
                completing my developer internship at Barclays. The one thing
                I've learned at school is that computers may not know lovin' but
                I certainly do. <br /> 5'9", 130lbs (dry) <br /> Tell me about
                yourself:
            </p>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <ApplicationInput
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    label="Name *"
                    error={formik.touched.name && formik.errors.name}
                />
                <ApplicationInput
                    id="occupation"
                    name="occupation"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.occupation}
                    label="Occupation *"
                    error={
                        formik.touched.occupation && formik.errors.occupation
                    }
                />
                <ApplicationInput
                    id="lookingFor"
                    name="lookingFor"
                    type="select"
                    onChange={formik.handleChange}
                    value={formik.values.lookingFor}
                    options={["Short Term", "Long Term", "IDK"]} // Add options for the select
                    label="What are you looking for? *"
                    error={
                        formik.touched.lookingFor && formik.errors.lookingFor
                    }
                />
                <ApplicationInput
                    id="age"
                    name="age"
                    type="radio"
                    value={formik.values.age}
                    onChange={formik.handleChange}
                    options={["18", "19", "20", "21", "22", "23", "MILF"]} // Add options for the select
                    label="Age *"
                    error={formik.touched.age && formik.errors.age}
                />
                <ApplicationInput
                    id="rizz"
                    name="rizz"
                    type="textarea"
                    onChange={formik.handleChange}
                    value={formik.values.rizz}
                    label="Rizz me up *"
                    error={formik.touched.rizz && formik.errors.rizz}
                />
                <ApplicationInput
                    id="email"
                    name="email"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    label="Email *"
                    error={formik.touched.email && formik.errors.email}
                />
                <AddImage
                    imageSrc={imageSrc}
                    setImageSrc={setImageSrc}
                    photoTaken={photoTaken}
                    setPhotoTaken={setPhotoTaken}
                    showCamera={showCamera}
                    setShowCamera={setShowCamera}
                />
                <p>
                    Keep an eye on your email for our response. Your information
                    is stored securely and never sold or shared - you’re in good
                    hands.
                </p>
                <div className="buttonGroup">
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    <Button variant="secondary" onClick={shareSite}>
                        Share this site
                    </Button>
                </div>
            </form>
        </div>
    );
}
