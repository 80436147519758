// HomePage.tsx
import React from "react";
import ApplicationForm from "../../components/ApplicationForm/ApplicationForm";
import Hero from "../../components/Hero/Hero";

function HomePage() {
    return (
        <div className="HomePage">
            <div className="Wrapper">
                <Hero></Hero>
                <ApplicationForm></ApplicationForm>
            </div>
        </div>
    );
}

export default HomePage;
