import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/Button";
import { Modal } from "../../components/Modal/Modal";
import { useShareSite } from "../../utils/ApplicationFormUtils";
import Confetti from "react-confetti";
import Approved from "../../../assets/Approved.png";
import Congrats from "../../../assets/Congrats.png";

import "./ApprovedPage.scss";

function ApprovedPage() {
    const { shareSite, modal, setModal } = useShareSite();

    // Snapchat and Instagram usernames
    const snapchatUsername = "mrjeisner";
    const instagramUsername = "mrjacobeisner";

    // Functions to open respective social apps
    const openSnapchat = () => {
        window.location.href = `snapchat://add/${snapchatUsername}`;
    };

    const openInstagram = () => {
        window.location.href = `instagram://user?username=${instagramUsername}`;
    };

    const [recycle, setRecycle] = useState(true);

    // On component mount, run confetti for 5000ms
    useEffect(() => {
        const timer = setTimeout(() => {
            setRecycle(false);
        }, 2000);

        return () => clearTimeout(timer); // Clear timeout if the component is unmounted
    }, []); // Empty dependency array makes this effect run only once on mount

    return (
        <div className="ApprovedPage">
            {<Confetti recycle={recycle} numberOfPieces={300} gravity={0.05} />}
            {modal.show && (
                <Modal
                    type={modal.type}
                    message={modal.message}
                    onClose={() =>
                        setModal({ show: false, type: "", message: "" })
                    }
                />
            )}
            <img className="ApprovedImage" src={Approved} alt="" />
            <div className="Picture">
                <img className="Congrats" src={Congrats} alt="" />
                <div className="Me"></div>
            </div>
            <div className="Socials">
                <p>Snapchat: {snapchatUsername}</p>
                <p>Instagram: {instagramUsername}</p>
            </div>
            <div className="ButtonGroup">
                <Button variant="primary" onClick={openSnapchat}>
                    Add me on SnapChat
                </Button>
                <Button variant="primary" onClick={openInstagram}>
                    Add me on Instagram
                </Button>
                <Button variant="secondary" onClick={shareSite}>
                    Share this site
                </Button>
            </div>
        </div>
    );
}

export default ApprovedPage;
