// App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./main/pages/HomePage/HomePage";
import SubmittedPage from "./main/pages/SubmittedPage/SubmittedPage";
import ApprovedPage from "./main/pages/ApprovedPage/ApprovedPage";
import "./App.scss";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyD5asiWJZe6tLFDPM6SYJi926HjHhUa-es",
    authDomain: "datejacob.firebaseapp.com",
    projectId: "datejacob",
    storageBucket: "datejacob.appspot.com",
    messagingSenderId: "886952387429",
    appId: "1:886952387429:web:5e2c94abe43078e5c9b78c",
    measurementId: "G-PMCKLTQ8GZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
const analytics = getAnalytics(app);

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/submitted" element={<SubmittedPage />} />
                    <Route path="/approved" element={<ApprovedPage />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
