// RadioInput.tsx
import React from "react";
import "./RadioInput.scss";

interface IRadioInputProps {
    name: string;
    label: string;
    options?: string[];
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    value?: string | number;
    error?: string | false | undefined;
}

export const RadioInput: React.FC<IRadioInputProps> = ({
    name,
    label,
    onChange,
    options,
    value,
    error,
}) => (
    <>
        <fieldset>
            <legend>{label}</legend>
            {options &&
                options.map((option, index) => (
                    <div className="RadioInput" key={index}>
                        <input
                            id={`${name}-${option}`}
                            name={name}
                            type="radio"
                            value={option}
                            checked={value === option}
                            onChange={onChange}
                        />
                        <label htmlFor={`${name}-${option}`}>{option}</label>
                    </div>
                ))}
        </fieldset>
        {error && <div className="error-message">{error}</div>}
    </>
);
