//ApplicationUtils.tsx

import { useCopyToClipboard } from "react-use";
import { useState } from "react";

import { object, string } from "yup";

import { collection, doc, setDoc } from "firebase/firestore";
import { firestore } from "../../App";
import { getStorage, ref, uploadString } from "firebase/storage";

export function useShareSite() {
    const [, copyToClipboard] = useCopyToClipboard();
    const [modal, setModal] = useState({ show: false, type: "", message: "" });

    const shareSite = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: "Date Jacob",
                    url: "https://datejacob.com",
                })
                .then(() => console.log("Successful share"))
                .catch((error) => console.log("Error sharing", error));
        } else {
            copyToClipboard("https://datejacob.com");
            setModal({
                show: true,
                type: "success",
                message: "Copied to clipboard!",
            });
        }
    };

    return { shareSite, modal, setModal };
}

export const schema = object({
    name: string().required("Required"),
    occupation: string().required("Required"),
    lookingFor: string().required("Required"),
    age: string().required("Required"),
    rizz: string().required("Required"),
    email: string().required("Required").email("Invalid Email"),
});

export interface FormValues {
    name: string;
    occupation: string;
    lookingFor: string;
    age: string;
    rizz: string;
    email: string;
}

export async function handleFormSubmit(
    values: FormValues,
    setSending: (value: boolean) => void,
    setImageSrc: (value: string | null) => void,
    setPhotoTaken: (value: boolean) => void,
    imageSrc: string | null,
    setModal: (value: { show: boolean; type: string; message: string }) => void,
    formik: { resetForm: () => void },
    navigate: (path: string) => void
) {
    setSending(true);
    try {
        const docRef = doc(collection(firestore, "Applications"), values.email);

        if (imageSrc) {
            const storage = getStorage();
            const storageRef = ref(storage, `images/${values.email}.png`);
            await uploadString(storageRef, imageSrc, "data_url");
        }

        await setDoc(docRef, values);

        navigate("/submitted");
    } catch (e) {
        setModal({
            show: true,
            type: "failure",
            message: "There was an error submitting your application.",
        });
    } finally {
        setSending(false);
        formik.resetForm();
        setImageSrc(null);
        setPhotoTaken(false);
    }
}
