import React from "react";
import flower from "../../../assets/flower.png";
import title from "../../../assets/Title.png";
import squigle from "../../../assets/Squigle.png";
import { Button } from "../Button/Button";
import { Link } from "react-scroll";
import "./Hero.scss";

export default function Hero() {
    return (
        <div className="Hero">
            <div className="Hero-Text">
                <img className="Title" src={title} alt="" />
            </div>
            <div className="Hero-Content">
                <img className="Flower" src={flower} alt="" />
                <div className="card-group">
                    <div className="card item"></div>
                    <div className="card item"></div>
                    <div className="card item"></div>
                    <div className="card item"></div>
                    <div className="heart item"></div>
                    <div className="heart item"></div>
                    <div className="heart-small item"></div>
                    <div className="heart item"></div>
                    <div className="heart-small item"></div>
                    <div className="heart item"></div>
                    <div className="heart-small item"></div>
                </div>
            </div>

            <Link
                to="ApplicationForm"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
            >
                <Button variant="primary">Tell me about yourself</Button>
            </Link>
            <img className="squigle" src={squigle} alt="" />
        </div>
    );
}
