// SubmittedPage.tsx
import React from "react";
import { Button } from "../../components/Button/Button";
import { Modal } from "../../components/Modal/Modal";
import { useShareSite } from "../../utils/ApplicationFormUtils";
import "./SubmittedPage.scss";
import Submitted from "../../../assets/Submitted.png";
import SubmittedCircle from "../../../assets/SubmittedCircle.png";

function SubmittedPage() {
    const { shareSite, modal, setModal } = useShareSite();

    return (
        <div className="SubmittedPage">
            {modal.show && (
                <Modal
                    type={modal.type}
                    message={modal.message}
                    onClose={() =>
                        setModal({ show: false, type: "", message: "" })
                    }
                />
            )}
            <img className="SubmittedImage" src={Submitted} alt="" />
            <div className="Picture">
                <img className="SubmittedCircle" src={SubmittedCircle} alt="" />
                <div className="Me"></div>
            </div>
            <div className="Socials">
                <p>Keep an eye on your email for our response!</p>
                <Button variant="secondary" onClick={shareSite}>
                    Share this site
                </Button>
            </div>
        </div>
    );
}

export default SubmittedPage;
