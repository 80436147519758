import * as React from "react";
import Webcam from "react-webcam";
import { Button } from "../../Button/Button";
import "./AddImage.scss";

interface AddImageProps {
    imageSrc: string | null;
    setImageSrc: React.Dispatch<React.SetStateAction<string | null>>;
    photoTaken: boolean;
    setPhotoTaken: React.Dispatch<React.SetStateAction<boolean>>;
    showCamera: boolean;
    setShowCamera: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddImage: React.FC<AddImageProps> = ({
    imageSrc,
    setImageSrc,
    photoTaken,
    setPhotoTaken,
    showCamera,
    setShowCamera,
}) => {
    const webcamRef = React.useRef<Webcam | null>(null);
    const buttonRef = React.useRef<HTMLButtonElement | null>(null); // Create ref for the button

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
            setImageSrc(imageSrc);
            setPhotoTaken(true);
        }
    }, [webcamRef, setImageSrc, setPhotoTaken]);

    const retake = React.useCallback(() => {
        setPhotoTaken(false);
    }, [setPhotoTaken]);

    const handleButtonClick = () => {
        setShowCamera(!showCamera);
        buttonRef.current?.scrollIntoView({ behavior: "smooth" }); // Scroll to the button
    };

    return (
        <div className="imageInput">
            <label htmlFor="addPhoto">Add a photo (Optional)</label>
            {/* scroll to view here */}
            <button
                ref={buttonRef}
                type="button"
                className="addPhoto"
                onClick={handleButtonClick}
            >
                {showCamera ? (
                    <span style={{ fontSize: 16 }}>Close</span>
                ) : (
                    <span style={{ fontSize: 25 }}>+</span>
                )}
            </button>

            {showCamera && (
                <>
                    {imageSrc && photoTaken && (
                        <img
                            src={imageSrc}
                            style={{
                                transform: "scaleX(-1)",
                                width: "100% !important",
                            }}
                            alt=""
                            className="userImage"
                        />
                    )}
                    {!photoTaken && (
                        <Webcam
                            style={{ transform: "scaleX(-1)" }}
                            audio={false}
                            width={640}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            height={480}
                            videoConstraints={{
                                width: 640,
                                height: 480,
                                facingMode: "user",
                            }}
                        ></Webcam>
                    )}
                    <button
                        type="button"
                        className="capture"
                        onClick={photoTaken ? retake : capture}
                    >
                        {photoTaken ? "Retake" : ""}
                    </button>
                </>
            )}
        </div>
    );
};
