// TextAreaInput.tsx
import React from "react";
import "./TextAreaInput.scss";

interface ITextAreaProps {
    name: string;
    id: string;
    label: string;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    value?: string | number;
    error?: string | false | undefined;
}

export const TextArea: React.FC<ITextAreaProps> = ({
    name,
    id,
    label,
    onChange,
    value,
    error,
}) => (
    <div className="TextAreaInput">
        <label htmlFor={name}>{label}</label>
        <textarea name={name} id={id} onChange={onChange} value={value} />
        {error && <div className="error-message">{error}</div>}
    </div>
);
