import * as React from "react";
import "./Modal.scss";
import { Button } from "../Button/Button";

interface ModalProps {
    type: "success" | "failure" | "sending" | string;
    message: string;
    onClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({ type, message, onClose }) => {
    return (
        <div className="Modal">
            <div className={`Modal-content ${type}`}>
                <h2>{message}</h2>
                {type !== "sending" && (
                    <Button variant="primary" onClick={onClose}>
                        Close
                    </Button>
                )}
            </div>
        </div>
    );
};
